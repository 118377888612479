function Brettspiel(
  name,
  kartenhuellen,
  image,
  boardgamegeekId,
) {
  return {
    name,
    kartenhuellen,
    image,
    boardgamegeekId,
  };
}

export default Brettspiel;
